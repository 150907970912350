import requestService from '@/services/request.service'
import { CardListType, CardType, RecCardType } from '@/types/card'
import { groupByModes, sortByModes } from '@/utils/constants'

const _numberFormatter = Intl.NumberFormat('en', { notation: 'compact' })

export const arrayBufferToBase64 = (buffer: number[] | ArrayBuffer) => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export const asPartnerLink = (card: CardType) =>
  card.partners_with && !['Amy Pond'].includes(card.name) // Exception for Amy Pond
    ? `/commanders/${formatUrl(card.name + '-' + card.partners_with)}`
    : `/${card.background_chooser || card.type.includes('Background') ? 'backgrounds' : 'partners'}/${
        card.sanitized_wo
      }`

export const bytesToBase64 = (input: string) => {
  const bytes = new TextEncoder().encode(input)
  const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join('')
  return btoa(binString)
}

export const cardListsToNames = (cardLists: CardListType[]) => {
  const cards = cardLists.reduce((acc: RecCardType[], cardList) => [...acc, ...cardList.cardviews], [])
  return cards.flatMap((card) => (card.cards ? card.cards.map((c) => c.url) : card.sanitized_wo))
}

export const fetchUrl = async (name: string) => {
  const res = await fetch(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/cards/${formatUrl(name)}`)
  const json = await res.json()
  const dir = json.legal_commander ? 'commanders' : 'cards'
  const url = `/${dir}/${json.url}`
  return url
}

export const flatten = (arr: any[]): any[] =>
  arr.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten), [])

export const formatNumber = (num: number) => _numberFormatter.format(num)

export const formatUrl = (str: string) =>
  str
    .normalize('NFD')
    .toLowerCase()
    .replace(/[^a-zA-Z0-9-_+\s\|/]/g, '')
    .replace(/\+/g, 'plus ')
    .replace(/[\s/]+/g, '-')
    .replace(/\|/g, '/')

export const extractSlug = (link: string | undefined) => {
  if (link === undefined) return undefined
  const parts = link.split('/')
  const url = parts.slice(3).join('/')
  return url
}

export const fullPath = (path: string | string[] | undefined) => (Array.isArray(path) ? path.join('/') : path)

export const getCardSize = (cardSize: 'xs' | 'sm' | 'lg' | 'xl' | 'md') => {
  switch (cardSize) {
    case 'xs':
      return { cardSize, height: 170, width: 122 }
    case 'sm':
      return { cardSize, height: 255, width: 183 }
    case 'lg':
      return { cardSize, height: 425, width: 305 }
    case 'xl':
      return { cardSize, height: 510, width: 366 }
    case 'md':
    default:
      return { cardSize, height: 340, width: 244 }
  }
}

export const isValidEmail = (str: string) =>
  str.match(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  )

export const mailtoBugReport = (stack?: string) => {
  if (!stack) return `mailto:bugreport@edhrec.com?subject=Bug%20Report`
  const body = encodeURIComponent(`\n\n--\n\n${window.location.href}\n\n${stack}`)
  return `mailto:bugreport@edhrec.com?subject=Bug%20Report&body=${body}`
}

export const modeKeyToValue = (key: string | undefined, type: 'group' | 'sort'): string | undefined => {
  if (!key) return
  if (type === 'group') return groupByModes.find((m) => m.key === key)?.value
  if (type === 'sort') return sortByModes.find((m) => m.key === key)?.value
}

export const scrollToTop = () => setTimeout(() => window.scrollTo(0, 0))

export const serverSidePropsFor =
  ({ options, url }: { options?: { unauthenticated?: boolean }; url: string }) =>
  async () => {
    const res = await (options?.unauthenticated ? requestService.get(url, {}, true) : requestService.get(url))
    if ([403, 404].includes(res.status)) {
      if (res.url.includes('/pages/tags/')) return { redirect: { destination: '/tags' } }
      return { notFound: true }
    }
    if (!res.ok) throw new Error('Failed to fetch data')
    const data = await res.json()
    if (data.redirect) return { redirect: { destination: data.redirect } }

    return { props: { data } }

    /*
     * SSR group and sort - too heavy for now
     * 
    
    if (!cardLists) return { props: { data, groupedBy, sortedBy } }

    let cardMap = {}

    // If groupBy or sortBy cookies are set, group/sort cardlists
    if (groupedBy || sortedBy) {
      const groupByMode = groupByModes.find((m) => m.key === groupedBy)
      const sortByMode = sortByModes.find((m) => m.key === sortedBy)

      try {
        // Fetch card data if needed
        if ((groupByMode && !groupByMode.skipFetch) || (sortByMode && !sortByMode.skipFetch)) {
          cardMap = await cardService.getCardMap(cardListsToNames(data.container.json_dict.cardlists))
        }

        // Group and sort
        if (groupedBy) cardLists = group({ cardLists, cardMap, mode: groupedBy })
        if (sortedBy) cardLists = sort({ cardLists, cardMap, mode: sortedBy })
      } catch (e: any) {
        console.error(e)
      }
    }

    return {
      props: { cardLists, cardMap, data, groupedBy, sortedBy },
    } */
  }

export const sha1Encode = async (message: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(message)
  const hashBuffer = await crypto.subtle.digest('SHA-1', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
}

export const tcgplayerLink = ({ subId1, u }: { subId1: string; u: string }) => {
  const params = new URLSearchParams({
    partnerpropertyid: '5237567',
    subId1: `edhrec,${subId1}`,
    u,
  })
  return 'https://partner.tcgplayer.com/c/4913290/1830156/21018?' + params.toString()
}
