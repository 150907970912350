import {
  faFacebook,
  faInstagram,
  faPatreon,
  faTiktok,
  faTwitch,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import {
  faAngleRight,
  faArrowDown,
  faArrowDown19,
  faArrowDownWideShort,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faC,
  faCalendar,
  faCaretDown,
  faCheck,
  faChessKing,
  faChessQueen,
  faCircle,
  faCircleQuestion,
  faClock,
  faClone,
  faCog,
  faCoins,
  faCrown,
  faDollarSign,
  faEllipsisV,
  faEnvelope,
  faEye,
  faFilter,
  faFire,
  faFireFlameSimple,
  faFont,
  faGear,
  faHashtag,
  faHatCowboy,
  faHeart,
  faHourglass,
  faHourglassEnd,
  faHourglassHalf,
  faHourglassStart,
  faHouse,
  faImage,
  faLandmark,
  faLink,
  faLock,
  faM,
  faMagnifyingGlass,
  faMinus,
  faMoneyBillWave,
  faMoon,
  faNewspaper,
  faPaperclip,
  faPercent,
  faPlus,
  faPodcast,
  faQuestion,
  faR,
  faRandom,
  faRotateLeft,
  faRotateRight,
  faRssSquare,
  faS,
  faSackDollar,
  faScroll,
  faSearch,
  faShirt,
  faSquareMinus,
  faSquarePlus,
  faStar,
  faStethoscope,
  faStopwatch,
  faSun,
  faTable,
  faTableCellsLarge,
  faTableList,
  faTag,
  faThumbsUp,
  faTimes,
  faTrash,
  faU,
  faUnlock,
  faUser,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

const EDHREC_ICONS = {
  angleRight: faAngleRight,
  arrowDown: faArrowDown,
  arrowDown19: faArrowDown19,
  arrowDownWideShort: faArrowDownWideShort,
  arrowLeft: faArrowLeft,
  arrowRight: faArrowRight,
  arrowUp: faArrowUp,
  c: faC,
  calendar: faCalendar,
  caretDown: faCaretDown,
  check: faCheck,
  chessKing: faChessKing,
  chessQueen: faChessQueen,
  circle: faCircle,
  circleQuestion: faCircleQuestion,
  clock: faClock,
  clone: faClone,
  cog: faCog,
  coins: faCoins,
  crown: faCrown,
  dollarSign: faDollarSign,
  ellipsisV: faEllipsisV,
  envelope: faEnvelope,
  eye: faEye,
  facebook: faFacebook,
  filter: faFilter,
  fire: faFire,
  fireFlameSimple: faFireFlameSimple,
  font: faFont,
  gear: faGear,
  hashtag: faHashtag,
  hatCowboy: faHatCowboy,
  heart: faHeart,
  hourglass: faHourglass,
  hourglassEnd: faHourglassEnd,
  hourglassHalf: faHourglassHalf,
  hourglassStart: faHourglassStart,
  house: faHouse,
  image: faImage,
  instagram: faInstagram,
  landmark: faLandmark,
  link: faLink,
  lock: faLock,
  m: faM,
  magnifyingGlass: faMagnifyingGlass,
  minus: faMinus,
  moneyBillWave: faMoneyBillWave,
  moon: faMoon,
  newspaper: faNewspaper,
  paperclip: faPaperclip,
  patreon: faPatreon,
  percent: faPercent,
  plus: faPlus,
  podcast: faPodcast,
  question: faQuestion,
  r: faR,
  random: faRandom,
  rotateLeft: faRotateLeft,
  rotateRight: faRotateRight,
  rss: faRssSquare,
  s: faS,
  sackDollar: faSackDollar,
  scroll: faScroll,
  search: faSearch,
  shirt: faShirt,
  squareMinus: faSquareMinus,
  squarePlus: faSquarePlus,
  star: faStar,
  stethoscope: faStethoscope,
  stopwatch: faStopwatch,
  sun: faSun,
  table: faTable,
  tableCellsLarge: faTableCellsLarge,
  tableList: faTableList,
  tag: faTag,
  thumbsUp: faThumbsUp,
  tiktok: faTiktok,
  times: faTimes,
  trash: faTrash,
  twitch: faTwitch,
  twitter: faTwitter,
  u: faU,
  unlock: faUnlock,
  user: faUser,
  wandMagicSparkles: faWandMagicSparkles,
  youtube: faYoutube,
}

export type EDHRECIconsType = keyof typeof EDHREC_ICONS

type Props = {
  icon: EDHRECIconsType
} & Omit<FontAwesomeIconProps, 'icon'>

const Icon: React.FC<Props> = (props: Props) => {
  return <FontAwesomeIcon {...props} icon={EDHREC_ICONS[props.icon]} />
}

export default Icon
