import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRef, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import Nav from 'react-bootstrap/Nav'
import BSNavbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import useClickAway from 'react-use/lib/useClickAway'
import { Persistor } from 'redux-persist/es/types'
import { PersistGate } from 'redux-persist/integration/react'
import { v4 } from 'uuid'

import logo from '@/assets/logo.png'
import logoSmall from '@/assets/logoSmall.png'
import AsyncTypeahead from '@/components/AsyncTypeahead/AsyncTypeahead'
import Clipboard from '@/components/Clipboard/Clipboard'
import Icon from '@/components/Icon/Icon'
import Mana, { ManaType } from '@/components/Mana/Mana'
import OutboundLink from '@/components/OutboundLink/OutboundLink'
import SettingsModal from '@/components/SettingsModal/SettingsModal'
import { useTheme } from '@/components/ThemeProvider/ThemeProvider'
import typeaheadService from '@/services/typeahead.service'
import { TOGGLE } from '@/store/clipboard/actions/types'
import { selectClipboardSize } from '@/store/clipboard/selectors'
import { useActionless, useAppSelector } from '@/store/hooks'
import { fetchMisc } from '@/store/misc/actions/fetchMisc'
import { SetType, TagType } from '@/store/misc/initialState'
import { AsyncTypeaheadEntryType } from '@/types/typeahead'
import { fetchUrl } from '@/utils/helpers'

import styles from './Navbar.module.scss'

type SubmenuType = {
  href?: string
  value?: string
  id?: string
  color?: ManaType
  colors?: string
  className?: string
  submenu?: SubmenuType[]
  title?: string
  icon_svg_uri?: string
}

type NavbarConfig = {
  id: string
  submenu: SubmenuType[]
  title: string
}

type Props = {
  persistor: Persistor
}

const Navbar = ({ persistor }: Props) => {
  const router = useRouter()
  const ref = useRef(null)
  const [theme] = useTheme()

  const clipboardIsOpen = useAppSelector((state) => state.clipboard.isOpen)
  const { sets, tags } = useAppSelector((state) => state.misc)
  const displayName = useAppSelector((state) => state.user.displayName)
  const clipboardSize = useAppSelector(selectClipboardSize)
  const [toggleClipboard] = useActionless(TOGGLE)

  const [expanded, setExpanded] = useState(false)
  const [showSettings, setShowSettings] = useState(false)

  useClickAway(ref, (event: any) => {
    const clickedClipboardButton = event.target.closest('.edhrec-clipboard-dont-close')
    if (!clickedClipboardButton && clipboardIsOpen) {
      toggleClipboard()
    }
  })

  useEffect(() => {
    setExpanded(false)
  }, [router.pathname])

  useEffect(() => {
    fetchMisc()
  }, [])

  const navbarConfig: NavbarConfig[] = [
    {
      id: 'navbar-cards',
      submenu: [
        { href: '/top', value: 'Top Cards' },
        { href: '/lands', value: 'Landbases' },
        { href: '/top/salt', value: 'Saltiest Cards' },
        {
          id: 'navbar-cards-by-color',
          submenu: [
            { color: 'w', href: '/top/white', value: 'White' },
            { color: 'u', href: '/top/blue', value: 'Blue' },
            { color: 'b', href: '/top/black', value: 'Black' },
            { color: 'r', href: '/top/red', value: 'Red' },
            { color: 'g', href: '/top/green', value: 'Green' },
            { color: 'c', href: '/top/colorless', value: 'Colorless' },
            { color: 'multi', href: '/top/multicolor', value: 'Multicolor' },
          ],
          title: 'By Color',
        },
        {
          id: 'navbar-cards-by-type',
          submenu: [
            { href: '/top/creatures', value: 'Creatures' },
            { href: '/top/instants', value: 'Instants' },
            { href: '/top/sorceries', value: 'Sorceries' },
            { href: '/top/artifacts', value: 'Artifacts' },
            {
              className: styles.indent,
              href: '/top/equipment',
              value: 'Equipment',
            },
            {
              className: styles.indent,
              href: '/top/utility-artifacts',
              value: 'Utility Artifacts',
            },
            {
              className: styles.indent,
              href: '/top/mana-artifacts',
              value: 'Mana Artifacts',
            },
            { href: '/top/enchantments', value: 'Enchantments' },
            { className: styles.indent, href: '/top/auras', value: 'Auras' },
            { href: '/top/planeswalkers', value: 'Planeswalkers' },
            { href: '/top/lands', value: 'Lands' },
            {
              className: styles.indent,
              href: '/top/utility-lands',
              value: 'Utility Lands',
            },
            {
              className: styles.indent,
              href: '/top/color-fixing-lands',
              value: 'Color-Fixing Lands',
            },
          ],
          title: 'By Type',
        },
        { href: '/combos', value: 'Combos' },
      ],
      title: 'Cards',
    },
    {
      id: 'navbar-commanders',
      submenu: [
        { href: '/commanders', value: 'Top Commanders' },
        { href: '/backgrounds', value: 'Backgrounds' },
        { href: '/partners', value: 'Partners' },
        {
          id: 'navbar-commanders-mono',
          submenu: [
            { color: 'w', href: '/commanders/mono-white', value: 'White' },
            { color: 'u', href: '/commanders/mono-blue', value: 'Blue' },
            { color: 'b', href: '/commanders/mono-black', value: 'Black' },
            { color: 'r', href: '/commanders/mono-red', value: 'Red' },
            { color: 'g', href: '/commanders/mono-green', value: 'Green' },
            { color: 'c', href: '/commanders/colorless', value: 'Colorless' },
          ],
          title: 'Mono',
        },
        {
          id: 'navbar-commanders-2-color',
          submenu: [
            { colors: 'wu', href: '/commanders/azorius', value: 'Azorius' },
            { colors: 'ub', href: '/commanders/dimir', value: 'Dimir' },
            { colors: 'br', href: '/commanders/rakdos', value: 'Rakdos' },
            { colors: 'rg', href: '/commanders/gruul', value: 'Gruul' },
            { colors: 'gw', href: '/commanders/selesnya', value: 'Selesnya' },
            { colors: 'wb', href: '/commanders/orzhov', value: 'Orzhov' },
            { colors: 'ur', href: '/commanders/izzet', value: 'Izzet' },
            { colors: 'bg', href: '/commanders/golgari', value: 'Golgari' },
            { colors: 'rw', href: '/commanders/boros', value: 'Boros' },
            { colors: 'gu', href: '/commanders/simic', value: 'Simic' },
          ],
          title: '2 Color',
        },
        {
          id: 'navbar-commanders-3-color',
          submenu: [
            { colors: 'wub', href: '/commanders/esper', value: 'Esper' },
            { colors: 'ubr', href: '/commanders/grixis', value: 'Grixis' },
            { colors: 'brg', href: '/commanders/jund', value: 'Jund' },
            { colors: 'rgw', href: '/commanders/naya', value: 'Naya' },
            { colors: 'gwu', href: '/commanders/bant', value: 'Bant' },
            { colors: 'wbg', href: '/commanders/abzan', value: 'Abzan' },
            { colors: 'urw', href: '/commanders/jeskai', value: 'Jeskai' },
            { colors: 'bgu', href: '/commanders/sultai', value: 'Sultai' },
            { colors: 'rwb', href: '/commanders/mardu', value: 'Mardu' },
            { colors: 'gur', href: '/commanders/temur', value: 'Temur' },
          ],
          title: '3 Color',
        },
        {
          id: 'navbar-commanders-4-5-color',
          submenu: [
            {
              colors: 'wubr',
              href: '/commanders/yore-tiller',
              value: 'Yore-Tiller',
            },
            {
              colors: 'ubrg',
              href: '/commanders/glint-eye',
              value: 'Glint-Eye',
            },
            {
              colors: 'brgw',
              href: '/commanders/dune-brood',
              value: 'Dune-Brood',
            },
            {
              colors: 'rgwu',
              href: '/commanders/ink-treader',
              value: 'Ink-Treader',
            },
            {
              colors: 'gwub',
              href: '/commanders/witch-maw',
              value: 'Witch-Maw',
            },
            {
              colors: 'wubrg',
              href: '/commanders/five-color',
              value: '5 Color',
            },
          ],
          title: '4+ Color',
        },
      ],
      title: 'Commanders',
    },
    {
      id: 'navbar-sets',
      submenu: [
        { href: '/sets', value: 'Sets' },
        ...sets.map(({ code, icon_svg_uri, name }: SetType) => ({
          className: styles.indent,
          code,
          href: `/sets/${code}`,
          icon_svg_uri,
          value: name,
        })),
        {
          className: styles.indentDouble,
          href: '/sets',
          value: 'More Sets...',
        },
        { href: '/precon', value: 'Precon Upgrades' },
      ],
      title: 'Sets',
    },
    {
      id: 'navbar-tags',
      submenu: [
        { href: '/tags', value: 'Tags' },
        ...tags.slice(0, 4).map(({ slug, name }: TagType) => ({
          className: styles.indent,
          href: `/tags/${slug}`,
          value: name,
        })),
        { className: styles.indent, href: '/tags', value: '...' },
        { href: '/tags/themes', value: 'Themes' },
        { href: '/tags/kindred', value: 'Kindred' },
        { href: '/companions', value: 'Companions' },
      ],
      title: 'Tags',
    },
  ]

  const handleRandom = async () => {
    const commandersTypeahead = await (await typeaheadService.getCommanders()).json()
    const i = Math.floor(Math.random() * commandersTypeahead.length)
    const random = commandersTypeahead[i]
    const url = await fetchUrl(random)
    router.push(url)
  }

  const handleSearch = async (value: AsyncTypeaheadEntryType[]) => {
    if (value.length > 0) {
      router.push(value[0].url)
    }
  }

  return (
    <>
      <SettingsModal handleClose={() => setShowSettings(false)} show={showSettings} />
      <div className={styles.spacer} />
      <BSNavbar
        bg='dark'
        className={classNames(styles.navbar, 'shadow-sm')}
        expand='lg'
        expanded={expanded}
        fixed='top'
        onToggle={() => setExpanded(!expanded)}
        ref={ref}
        variant='dark'
      >
        <PersistGate persistor={persistor}>
          <Clipboard />
        </PersistGate>
        <BSNavbar.Brand className='d-none d-lg-flex'>
          <Link href='/'>
            <Image alt='EDHREC' className={styles.logo} src={logo.src} width={148} height={40} />
          </Link>
        </BSNavbar.Brand>
        <div className={styles.brandSmall}>
          <Link href='/'>
            <Image alt='EDHREC' className={styles.logo} src={logoSmall.src} width={40} height={40} />
          </Link>
          <InputGroup className={styles.searchSmall}>
            <AsyncTypeahead className={styles.typeahead} onChange={handleSearch} clearOnChange placeholder='Search' />
          </InputGroup>
          <div className='d-flex gap-2'>
            <Button className={styles.button} onClick={toggleClipboard} variant={theme}>
              <div className='align-items-center d-flex gap-1'>
                <Icon icon='paperclip' />
                {clipboardSize}
              </div>
            </Button>
            <BSNavbar.Toggle />
          </div>
        </div>
        <BSNavbar.Collapse>
          <Nav className='me-auto'>
            {navbarConfig.map(({ id, submenu, title }) => (
              <NavDropdown className={styles.dropdown} id={id} key={v4()} title={title}>
                {submenu.map(({ className, href, icon_svg_uri, id, submenu, title, value }) =>
                  submenu ? (
                    <NavDropdown className={styles.subDropdown} id={id as string} key={id} title={title}>
                      {submenu.map(({ className, color, colors, href, value }) => (
                        <NavDropdown.Item as={Link as any} className={className} key={href} href={href} active={false}>
                          <span className={styles.manaRow}>
                            {(color || colors) && <Mana color={color} colors={colors} key={color} />}

                            <span className={styles.text}>{value || title}</span>
                          </span>
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  ) : (
                    <NavDropdown.Item as={Link as any} className={className} key={v4()} href={href} active={false}>
                      <span>
                        {icon_svg_uri && <img alt={value} className={styles.setIcon} src={icon_svg_uri} />}
                        {value}
                      </span>
                    </NavDropdown.Item>
                  ),
                )}
              </NavDropdown>
            ))}
            <Nav.Link as={Link as any} className={styles.recs} href='/recs' active={false}>
              Recs
            </Nav.Link>
          </Nav>
          <Nav className={styles.buttonContainer}>
            <div className={classNames(styles.buttonContainer, 'd-none d-lg-flex')}>
              <OverlayTrigger overlay={(<Tooltip id='button-tooltip'>Clipboard</Tooltip>) as any} placement='bottom'>
                <Button className={styles.button} onClick={toggleClipboard} variant={theme}>
                  <div className='align-items-center d-flex gap-1'>
                    <Icon icon='paperclip' />
                    {clipboardSize}
                  </div>
                </Button>
              </OverlayTrigger>
            </div>
            <InputGroup className={classNames(styles.search, 'd-none d-lg-flex')}>
              <AsyncTypeahead className={styles.typeahead} clearOnChange onChange={handleSearch} placeholder='Search' />
            </InputGroup>
            {displayName ? (
              <div className={styles.buttonContainer}>
                <Button
                  as={Link as any}
                  className={classNames(styles.button, styles.displayName)}
                  href='/account'
                  variant={theme}
                >
                  <Icon className={styles.communityIcon} icon='user' />
                  <span>{displayName}</span>
                </Button>
              </div>
            ) : (
              <>
                <div className={styles.buttonContainer}>
                  <Button as={Link as any} className={styles.button} href='/signin' variant={theme}>
                    Sign In
                  </Button>
                  <Button
                    as={Link as any}
                    className={classNames(styles.button, styles.signUp)}
                    href='/signup'
                    variant='primary'
                  >
                    Sign Up
                  </Button>
                </div>
              </>
            )}
            <div className={classNames(styles.buttonContainer, 'd-flex d-lg-none')}>
              <Button className={styles.button} onClick={handleRandom} variant={theme}>
                <Icon icon='random' />
                {' Random'}
              </Button>
              <Button className={styles.button} onClick={() => setShowSettings(true)} variant={theme}>
                <Icon icon='gear' />
                {' Settings'}
              </Button>
            </div>
          </Nav>
        </BSNavbar.Collapse>
      </BSNavbar>

      <BSNavbar bg='dark' className={classNames(styles.navbar2, 'shadow-sm')} variant='dark'>
        <Nav className='me-auto'>
          <Nav.Link
            as={Link as any}
            className={styles.navbar2link}
            url='https://edhrec.com/articles'
            href='/articles'
            active={false}
          >
            <Icon className='me-1' fixedWidth icon='rss' />
            Articles
          </Nav.Link>
          <Nav.Link
            as={OutboundLink}
            className={styles.navbar2link}
            url='https://www.youtube.com/channel/UCApiDwDpn_JpzRGLZKrdr1g'
            utm_medium='navbar'
            active={false}
          >
            <Icon className='me-1' fixedWidth icon='podcast' />
            EDHRECast
          </Nav.Link>
          <Nav.Link
            as={Link as any}
            className={styles.navbar2link}
            url='https://edhrec.com/faq'
            href='/faq'
            active={false}
          >
            <Icon className='me-1' fixedWidth icon='question' />
            FAQ
          </Nav.Link>
          <Nav.Link
            as={Link as any}
            className={styles.navbar2link}
            url='https://edhrec.com/newsletter'
            href='/newsletter'
            active={false}
          >
            <Icon className='me-1' fixedWidth icon='newspaper' />
            Newsletter
          </Nav.Link>
          <Nav.Link
            active={false}
            as={OutboundLink}
            className={styles.navbar2link}
            url='https://www.patreon.com/edhrec'
            utm_medium='navbar'
          >
            <Icon className='me-1' fixedWidth icon='patreon' />
            Patreon
          </Nav.Link>
        </Nav>
        <Nav className={styles.buttonContainer}>
          <div className={styles.buttonContainer}>
            <OverlayTrigger overlay={(<Tooltip id='button-tooltip'>Random</Tooltip>) as any} placement='bottom'>
              <Button className={styles.button} onClick={handleRandom} size='sm' variant='dark'>
                <Icon icon='random' />
              </Button>
            </OverlayTrigger>
          </div>
          <div className={styles.buttonContainer}>
            <OverlayTrigger overlay={(<Tooltip id='button-tooltip'>Contact us</Tooltip>) as any} placement='bottom'>
              <Link href='/contact-us'>
                <Button className={styles.button} size='sm' variant='dark'>
                  <Icon icon='envelope' />
                </Button>
              </Link>
            </OverlayTrigger>
          </div>
          <div className={styles.buttonContainer}>
            <OverlayTrigger overlay={(<Tooltip id='button-tooltip'>Settings</Tooltip>) as any} placement='bottom'>
              <Button className={styles.button} onClick={() => setShowSettings(true)} size='sm' variant='dark'>
                <Icon icon='cog' />
              </Button>
            </OverlayTrigger>
          </div>
          <span>&nbsp;</span>
        </Nav>
      </BSNavbar>
    </>
  )
}

export default Navbar
